import React, { useEffect, useState } from 'react'
import { BottomLabelDonutContainer, CompanyHeader, CompanyName, DepartmentDropdown, GraphContainer, RecognitionReceived,TopDepartmentGraph } from '../../Recognition/CompanyAnalytics/styles';
import PropTypes from "prop-types";
import TopDepartment from '../../Recognition/CompanyAnalytics/TopDepartment';
import { isEmpty, isNull, isUndefined } from 'lodash';
import Table from './Table';
import LazyImage from '../LazyImage/LazyImage';
import { ImageUrl } from '../../../utils/constants';


const TopSection = (props) => {
  window.console.log("donut char dats is",props)
  const{title, topData, topSectionKey,colors, keys, seeAllTitle, tableData, tableTitle, tableColumnTitle, tableColumnKey, tableRowKey, id, topFallBackText, selectedYearInfo, yearDataList, onYearSelect, isYearDropdown, isYearChanged } = props;

  const[isOpen,setIsOpen]=useState(false);

  // window.console.log("selectedYearInfo", selectedYearInfo)
  // window.console.log("yearDataList", yearDataList)
  // window.console.log("onYearSelect", onYearSelect)

  const getFrequencyOfEating = () => {

    let departmentsTotalSum = 0;
    let departmentsIndividualSum = [];
    let topDepartmentsName = [];
    if (!isEmpty(topData)) {
      topData.forEach((data) => {
        const sum = keys.reduce((acc, keyObj) => Number(acc) + Number(data[keyObj.key]), 0);
        departmentsTotalSum += sum;
        departmentsIndividualSum.push(sum);
        topDepartmentsName.push(data[topSectionKey]);
      });
    }

    const TopSectionArrays = {}; // Use an object to store arrays

    // Assuming you have a prop called 'sectionName' with a dynamic value
    const sectionName = id;

    // Create an array for the current section name and store it in the object
    TopSectionArrays[sectionName] = [];
    

    !isUndefined(topDepartmentsName) && !isNull(topDepartmentsName) && !isEmpty(topDepartmentsName) ?
      topDepartmentsName.length > 0 && topDepartmentsName.map((data, index) => {
        const obj = {};
        let percentage = departmentsTotalSum ? (departmentsIndividualSum[index] / departmentsTotalSum) * 100 : 0;
        obj['name'] = data;
        obj['color'] = colors[index];
        obj['y'] = Math.round(percentage);
        TopSectionArrays[sectionName].push(obj);
      }) : '';
    return TopSectionArrays[sectionName];
    
  };
  const hideTablePopup = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if(isYearDropdown){
      if(isYearChanged){
        setIsOpen(true)
      }
    }
  },[topData])
  return (
    <React.Fragment>
      <GraphContainer style={{marginBottom:'25px', boxShadow:'0px 8px 16px 0px #005C8720'}}>
        <CompanyHeader>
          {title}
        </CompanyHeader>
        <TopDepartmentGraph>
          <TopDepartment
            container={`donut-chart-${id}`}
            chartData={getFrequencyOfEating()}
          />
          {topData && topData.length >0 ? <BottomLabelDonutContainer style={{height:'100%'}} container={'sugar-intake'} justifyContent={topData.length === 2 ? 'space-around' : 'space-between'}>
            {
              topData.map((item, index) => {
                const color = colors[index];
                return <div key={index}>
                  <CompanyName>
                    <span style={{ backgroundColor: color }} />
                    <span>{item[topSectionKey]}</span>
                  </CompanyName>
                  {keys.map((keyObj, index)=>(                  
                    <RecognitionReceived key={index}>
                      {item[keyObj.key]}
                      <p>{keyObj.text}</p>
                    </RecognitionReceived>))}
                </div>
              })
            }
          </BottomLabelDonutContainer>: (<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:"100%"}}>
            <span style={{color:'rgba(0, 92, 135, 0.60)',fontSize:'18px', lineHeight:'32px', width:'53%', marginTop:'40px', textAlign:"center"}}>{topFallBackText ? topFallBackText : "No Data Found"}</span></div>)}
        </TopDepartmentGraph>

        {tableData && tableData.length > 0 && <DepartmentDropdown>
          <button className='alldepartment-btn1' onClick={() => setIsOpen(!isOpen)}>
            <button className='alldepartment-btn2' style={{color:'#005c87'}}>{seeAllTitle}
              {!isOpen ? <LazyImage src={ImageUrl + '/images/rightArrow.svg'} /> :<LazyImage src={ImageUrl + '/images/downArrow.svg'} />}
            </button>
          </button>
        </DepartmentDropdown> }
      </GraphContainer>

      {isOpen && 
        // <StyledModalV2 transform={1} show={isOpen} onHide={hideTablePopup} >
        <Table tableData={tableData} isYearDropDown={isYearDropdown} selectedYearInfo={selectedYearInfo} yearDataList={yearDataList} onYearSelect={onYearSelect} show={isOpen} hide={hideTablePopup} id={id} tableTitle={tableTitle} tableColumnTitle={tableColumnTitle} tableRowKey={tableRowKey} tableColumnKey={tableColumnKey}/>
        // </StyledModalV2>
      }


    </React.Fragment>
  )
}


TopSection.propTypes = {
  topData: PropTypes.array.isRequired,
  title: PropTypes.string,
  topSectionKey: PropTypes.string.isRequired,
  colors:PropTypes.array.isRequired,
  keys:PropTypes.array.isRequired,
  seeAllTitle: PropTypes.string,
  tableData:PropTypes.array.isRequired,
  tableTitle:PropTypes.string,
  tableColumnTitle:PropTypes.string.isRequired,
  tableColumnKey:PropTypes.string.isRequired,
  tableRowKey:PropTypes.string.isRequired,
  id:PropTypes.string.isRequired,
  topFallBackText:PropTypes.string,
  selectedYearInfo: PropTypes.number,
  yearDataList : PropTypes.array,
  onYearSelect : PropTypes.func,
  isYearDropdown : PropTypes.bool,
  isYearChanged : PropTypes.bool
};

export default TopSection