/* eslint-disable no-undef*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CustomButton, CustomOrderedList } from '../MedicalForm/styles';

import QuestionComponent from '../QuestionComponentV2';
import Waiting from '../Waiting';

import { saveWorkplaceData, getWorkplaceData, saveWellbeingAssessment, getUser } from "../../redux/actions";

class WorkplaceForm extends Component {

  componentDidMount() {
    this.props.fetchWorkplaceData();
  }

  navigateUser = (e, value) => {
    e.preventDefault();
    const { history } = this.props;
    history.push(value);
  };

  saveAssessment = (e) => {
    e.preventDefault();
    const { saveWellbeingAssessment, history, getUser } = this.props;
    saveWellbeingAssessment(history);
    getUser()
  };

  render() {
    const { workplaceData, addWorkplaceData, isLoading, error, hraQuestionsAnswersData } = this.props;
    if(isLoading && _.isEmpty(workplaceData) && error) {
      return <Waiting />
    }
    return (
      <form>
        <CustomOrderedList>
          {
            hraQuestionsAnswersData['workplace'].map((section,index) => {
              let addedAnswers = '';
              workplaceData && workplaceData.length > 0 && (workplaceData.length <= hraQuestionsAnswersData['workplace'].length) && workplaceData.map((data) => {
                if(data.question === section.question_id) {
                  addedAnswers = data.answer
                }
              });
              return (
                <QuestionComponent
                  key={section.question}
                  section={section}
                  saveData={addWorkplaceData}
                  addedAnswers={addedAnswers}
                  color={"#F4D463"}
                  questionNo={index+1}
                />
              )
            })
          }
        </CustomOrderedList>
        <CustomButton
          left
          onClick={(e) => this.navigateUser(e, '/wellness-assessment/stress')}
        >
          back
        </CustomButton>
        <CustomButton
          onClick={this.saveAssessment}
          disabled={_.isUndefined(workplaceData) || workplaceData.length<hraQuestionsAnswersData['workplace'].length}
        >
          submit
        </CustomButton>
      </form>
    )
  }
}

WorkplaceForm.propTypes = {
  addWorkplaceData: PropTypes.func.isRequired,
  fetchWorkplaceData: PropTypes.func.isRequired,
  workplaceData: PropTypes.array,
  history: PropTypes.object.isRequired,
  saveWellbeingAssessment: PropTypes.func.isRequired,
  success: PropTypes.string,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  getUser : PropTypes.func,
  hraQuestionsAnswersData: PropTypes.array
};

const mapStateToProps = (state) => ({
  workplaceData: state.wellBeingAssessment.workplaceData,
  success: state.wellBeingAssessment.success,
  error: state.wellBeingAssessment.error,
  isLoading: state.profileData.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  addWorkplaceData: (question, answer, isMultipleAnswers, count) => dispatch(saveWorkplaceData(question, answer, isMultipleAnswers, count)),
  fetchWorkplaceData: () => dispatch(getWorkplaceData()),
  saveWellbeingAssessment: (history) => dispatch(saveWellbeingAssessment(history)),
  getUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceForm);